/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { curry, __ } from 'ramda';
import { IntlProvider } from 'react-intl';
import { formatPostDate } from 'utils/helpers';
import makeLoadMessage from './makeLoadMessage';

const loadMessage = makeLoadMessage();

const initValues = {
  lang: 'en',
  baseUrl: '/',
  formatDate: (dateStr) => {},
};
const LanguageContext = React.createContext(initValues);
const curriedFormatPostDate = curry(formatPostDate);

function LanguageProvider({ lang, baseUrl, children }) {
  const value = React.useMemo(() => {
    return {
      lang,
      baseUrl,
      formatDate: curriedFormatPostDate(__, lang),
    };
  }, [baseUrl, lang]);

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={lang} messages={loadMessage(lang)}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

LanguageProvider.propTypes = {
  lang: PropTypes.string,
  baseUrl: PropTypes.string,
  children: PropTypes.any.isRequired,
};

LanguageProvider.defaultProps = {
  lang: 'en',
  baseUrl: '/',
};

const useLang = () => {
  const langContext = useContext(LanguageContext);

  return langContext;
};

export { LanguageContext as default, LanguageProvider, useLang };
