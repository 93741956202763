import 'style/global.scss';
import 'utils/fixFontAwesome';

import customWrapPageElement from 'utils/wrapPageElement';

export const wrapPageElement = customWrapPageElement;

// hide loading layer for first load
export const onInitialClientRender = () => {
  document.getElementById('___loader').style.display = 'none';
};
