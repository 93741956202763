/* eslint-disable react/prop-types */
import React from 'react';
import { LanguageProvider } from 'context/LanguageContext';

const wrapPageElement = ({
  element,
  props: {
    pageContext: { langKey, baseUrl },
  },
}) => (
  <LanguageProvider lang={langKey} baseUrl={baseUrl}>
    {element}
  </LanguageProvider>
);

export default wrapPageElement;
